<template>
    <div></div>
<div class="flex">
    <div class="bg-white shadow-md my-2  w-11/12 mx-auto p-4 rounded-3xl">
        <div>
            <div class="text-right float-right">
                <p class="text-orange font-bold  -mb-1">{{ Math.round(course.rating * 10) / 10  }}<span class="fa fa-star text-lg text-orange"></span></p>
                <p class="text-extralight text-xs text-gray-500">from <span class="text-orange font-bold">{{course.rated_sessions}}</span> sessions</p>
            </div>
            <div>
                <p class=" capitalize text-left my-2 font-poppins font-normal text-xs text-extralight -mb-1 text-orange">{{course.tutor.in_person? 'in-person & online': 'online'}}</p>
                <p class="font-bold text-lg leading-7 capitalize">{{course.class.name}}</p>
                <p class="text-extralight font-poppins font-normal text-sm text-gray-400 leading-5">{{course.class.desc}}</p>                        
            </div>
        </div>
        <div class="flex flex-row justify-center items-center">
        <button
          class="
            bg-orange
            rounded-full
         px-7
            font-poppins
          font-medium
         text-xs
         py-3
            text-white
            capitalize
          "
          @click="takeClass()"
        >
        take class
        </button>
    </div>
    </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { mapMutations, mapState } from 'vuex'

export default {
    props: {
        course: Object
    },
    setup() {
        const router = useRouter();
        return { router };
    },
    computed: {
        ...mapState(['filler_img'])
    },
    methods: {
        ...mapMutations(['SetSessionPreviewClass']),
        takeClass(){
            this.SetSessionPreviewClass(this.course);
            this.$router.replace('time');
        }
    },        
}
</script>