<template>
 <page-layout linkDefault="/" title="Pick a class" :backButton="true">
    <div class="my-4">
      <transition-group name="scale-up" appear tag="ul">
        <li v-for="course in this.tutor_previewed.courses" :key="course._id">
          <class-expanded :course="course" />
        </li>
      </transition-group>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "../components/base/pageLayout.vue";
import ClassExpanded from "../components/ClassExpanded.vue";
import { mapMutations, mapState } from "vuex";
import { useRouter } from "vue-router";
import mixpanel from "mixpanel-browser";

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  created() {
    this.loadSessionInfo();
    mixpanel.track("visited book class", {
      tutor: this.tutor_previewed.user.username,
      ...this.session_previewed,
    });
  },
  computed: {
    ...mapState(["tutor_previewed", "session_previewed"]),
  },
  methods: {
    ...mapMutations(["setTutorPreviewed", "loadSessionInfo"]),
  },
  components: {
    PageLayout,
    ClassExpanded,
  },
  name: "PickClass",
};
</script>
